.trainingSpotContainer {
    width: 25rem;
    max-width: 90%;
    height: fit-content;
    border: 1px solid;
    text-align: left;
    padding: 1px;

    &__pictures {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;

        &__buttons {
            max-height: 1.5rem;
            margin-top: auto;
            gap: 2px;
            display: flex;
        }
    }

    &__description {
        display: flex;
        justify-content: space-between;

        &__coords {
            display: flex;
            gap: 0.5rem;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
    }
}