.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the App takes the full height of the viewport */
  align-items: center;
  text-align: center;
  gap: 0.25rem;
}
header {
  background-color: #7ce6b690;
  width: 100%;
  padding: 0.75rem 0;
}
main {
  width: 100%;
}
// App.scss
.loading-overlay {
  position: fixed;
  background-color: white;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-overlay .spinner-wrapper {
  margin: auto;
}
