.container {
  max-width: 90%;
  border: 1px solid;
  border-radius: 0.25rem;
  width: 15rem;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  &__countryLink {
    gap: 0.25rem;
    margin: auto;
    display: flex;
  }
}
